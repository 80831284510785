import React from "react";
import "./styles.css";
import Display from "./Display";
import { BrowserRouter as Router, Route } from "react-router-dom";
export default function App() {
  return (
    <Router>
      <Route path="*">
        <Display />
      </Route>
    </Router>
  );
}
