import React from "react";
import "./styles.css";

import { useLocation } from "react-router-dom";
export default function Display() {
  let { pathname } = useLocation();

  let data = pathname.split("").slice(1).join("");
  return (
    <div className="App">
      <h1>Data to Copy is below</h1>
      <h5>To use this, all you need to do is append the data you 
        want to the url in the address bar. For instance:-
        if the url is 'www.example.com', append your data this way:-
        'www.example.com/data information'<br/> 
        then use the send feature of chrome to send the link to the desired device </h5>

      <h2>data:- {data}</h2>
    </div>
  );
}
